import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Frontend/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getSaveAddressInformation(addressInformation, guestCartId, pickup) {
        const mutation = new Field('saveAddressInformation')
            .addArgument('addressInformation', 'SaveAddressInformation!', addressInformation)
            .addFieldList(this._getSaveAddressInformationFields(guestCartId, pickup));

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    _getSaveAddressInformationFields(guestCartId, pickup) {
        return [this._getPaymentMethodsField(), this._getTotalsField(), this._getPickupField(guestCartId, pickup)];
    }

    _getPickupField(guestCartId, pickup) {
        const pickupMutation = new Field('pickup')
            .addArgument('pickup', 'PickupInput', pickup)
            .addFieldList(['is_express']);

        this._addGuestCartId(guestCartId, pickupMutation);
        return pickupMutation;
    }

    getPlaceOrderMutation(guestCartId, consents) {
        const mutation = new Field('s_placeOrder')
            .addArgument('consents', '[ConsentDataInput]', consents)
            .setAlias('placeOrder')
            .addField(this._getOrderField());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }
}
export default new CheckoutQuery();
