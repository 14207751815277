import i18n from './i18n';

/** @namespace Webpacki18nRuntimeCustom/Scandipwa/Util/__/injectValues */
export function injectValues(string, ...values) {
    // eslint-disable-next-line fp/no-let
    let i = 0;
    return string.replace(/%s/g, () => values[i++]);
}

/** @namespace Webpacki18nRuntimeCustom/Scandipwa/Util/__/translateString */
export function translateString(string) {
    return i18n.currentTranslation[string] || string;
}

/** @namespace Webpacki18nRuntimeCustom/Scandipwa/Util/__/getTranslatedStringWithInjectedValues */
export function getTranslatedStringWithInjectedValues(string, values) {
    return injectValues(translateString(string), ...values);
}

/** @namespace Webpacki18nRuntimeCustom/Scandipwa/Util/__ */
export class TranslatedValue extends String {
    // Translate and inject values during the initialization
    __construct(value, args = []) {
        super.__construct();

        this.value = value;
        this.injectables = args;
    }

    // Reload the translation each time toString() is  called
    toString() {
        return getTranslatedStringWithInjectedValues(this.value, this.injectables);
    }
}

export default function __(string, ...values) {
    const translateToString = new TranslatedValue(string, values);
    return translateToString.toString();
}
